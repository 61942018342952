import { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import { AppStoreButton } from 'react-mobile-app-button';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import LogoutButton from '../../components/LogoutButton/LogoutButton';
import { QRCodeSVG } from 'qrcode.react';
import { ToastContainer } from 'react-toastify';

const logo = require('../../assets/images/fably-logo.png');
const url = 'https://www.npmjs.com/package/react-mobile-app-button';

function HaveAccountPage() {
  const { t } = useTranslation();
  const { goTo } = useCommonFunctions();

  useEffect(() => {
    document.title = t('alreadyHaveAnAccount');
  }, [t]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <LogoutButton />
      <div className="container">
        <ToastContainer hideProgressBar={true} className="toast" />
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo mt-60" />
          <div className="mt-40 mb-30 flex-column align-center-h">
            <h3 className="nm-top-text cl-red">{`${t(
              'youAreAlreadyPremiumMember'
            )}`}</h3>
            <p className="nm-bottom-text sm-text">
              {t('alreadyHavePremiumAccess')}
            </p>
            <h3 className="cl-red">{t('downloadScanningCode')}</h3>
            <div className="qr-code">
              <QRCodeSVG
                value={url}
                size={160}
                level={'H'}
                bgColor="#FFFFFF"
                fgColor="#000000"
              />
            </div>
            <div className="store-btn">
              <AppStoreButton
                url={url}
                theme="dark"
                title={t('downloadOnThe')}
                width={160}
              />
            </div>
            <button
              type="button"
              onClick={() => goTo('/')}
              className="loginButton"
            >
              {t('backToHomepage')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HaveAccountPage;
