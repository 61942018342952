import { useEffect, useRef, useState } from 'react';

const useTimer = (initialDuration = 3 * 60) => {
  const [remainingTime, setRemainingTime] = useState(initialDuration);
  const [isTabActive, setIsTabActive] = useState(true);
  const lastTabStateChange = useRef(null);

  useEffect(() => {
    setRemainingTime(initialDuration);
  }, [initialDuration]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const currentTime = Date.now();

      if (document.visibilityState === 'hidden') {
        lastTabStateChange.current = currentTime;
        setIsTabActive(false);
      } else if (document.visibilityState === 'visible') {
        if (lastTabStateChange.current) {
          const elapsed = Math.floor(
            (currentTime - lastTabStateChange.current) / 1000
          );
          setRemainingTime((prevTime) => Math.max(prevTime - elapsed, 0));
          lastTabStateChange.current = null;
        }
        setIsTabActive(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTabActive) {
        setRemainingTime((prevTime) => Math.max(prevTime - 1, 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTabActive]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return {
    remainingTime,
    formattedTime: formatTime(remainingTime),
  };
};

export default useTimer;
