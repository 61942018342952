import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import './styles.css';

const PasswordInputWithIcon = ({ password, setPassword, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="inputContainer">
      <input
        type={showPassword ? 'text' : 'password'} // Alterna entre text y password
        placeholder={placeholder}
        className="passwordStyle"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <span onClick={togglePasswordVisibility} className="spanStyle">
        {showPassword ? (
          <FiEyeOff size={20} color="#b1b6bc" />
        ) : (
          <FiEye size={20} color="#b1b6bc" />
        )}
      </span>
    </div>
  );
};

export default PasswordInputWithIcon;
