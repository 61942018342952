import {
  createSubscription,
  verifyUserSubscription,
} from '../../services/paymentService/paymentService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const PaymentController = (type) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem('auth');
      if (userId) {
        const subscription = await createSubscription(type, userId);
        if (subscription.checkoutUrl) {
          setLoading(false);
          window.location.href = subscription.checkoutUrl;
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.message) {
        toast.error(t(error.message.split(' ')[0]), {
          onClose: () => {
            if (
              error.message.split(' ')[0] === 'Unauthorized,' ||
              error.message === 'notFound'
            ) {
              localStorage.removeItem('auth');
              window.location.reload();
            }
          },
        });
      }
    }
  };

  const userHaveSubscription = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem('auth');
      if (userId) {
        const subscription = await verifyUserSubscription();
        return subscription;
      }
    } catch (error) {
      setLoading(false);
      if (error.message) {
        toast.error(t(error.message.split(' ')[0]), {
          onClose: () => {
            if (
              error.message.split(' ')[0] === 'Unauthorized,' ||
              error.message === 'notFound'
            ) {
              localStorage.removeItem('auth');
              window.location.reload();
            }
          },
        });
      }
    }
  };

  return { loading, handlePayment, userHaveSubscription };
};

export default PaymentController;
