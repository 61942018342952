/* eslint-disable react-hooks/exhaustive-deps */
import { ToastContainer } from 'react-toastify';
import PlanOption from '../../components/PlanOption/PlanOption';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
// import { SyncLoader } from 'react-spinners';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import useSelectPlan from '../../hooks/useSelectPlan';
import PaymentController from '../../controllers/PaymentController.js/PaymentController';
import LogoutButton from '../../components/LogoutButton/LogoutButton';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import useManageModal from '../../hooks/useManageModal';

const logo = require('../../assets/images/fably-logo.png');

function PlansPage() {
  const { t } = useTranslation();
  const { selectedPlan, handleSelectPlan } = useSelectPlan();
  const { handlePayment, loading } = PaymentController(
    selectedPlan?.type || ''
  );
  const { goTo } = useCommonFunctions();
  const { showModal, handleOpenModal, handleCloseModal } = useManageModal();

  useEffect(() => {
    document.title = t('plans');
  }, [t]);

  useEffect(() => {
    const haveSubscription = localStorage.getItem('haveSubscription');

    if (haveSubscription) {
      goTo('/AlreadyHaveAccount');
    }
  }, [goTo]);

  // const override = {
  //   display: 'block',
  //   margin: '0 auto',
  //   borderColor: 'white',
  // };

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <LogoutButton />
      <ToastContainer hideProgressBar={true} className="toast" />
      <div className="container-l">
        <ModalComponent
          closeModal={() => handleCloseModal()}
          modalIsOpen={showModal}
          title={t('onlyOnIphone')}
          text={t('onlyOnIpgoneText')}
          buttonText={t('gotIt')}
          buttonAction={handlePayment}
          loading={loading}
        />
        <img src={logo} alt={t('fablyLogo')} className="fablyLogo mt-100" />
        <h4>{`${t('step')} 2: ${t('chooseYourPlan')}`}</h4>
        <div className="spacer" />
        <div className="plans-cards">
          <PlanOption
            type="monthly"
            price={6.99}
            secondaryPrice={83.88}
            selected={selectedPlan?.type === 'monthly'}
            onSelect={() =>
              handleSelectPlan({
                type: 'monthly',
              })
            }
          />
          <PlanOption
            type="annual"
            price={71.88}
            secondaryPrice={5.99}
            discount={14}
            selected={selectedPlan?.type === 'annual'}
            onSelect={() =>
              handleSelectPlan({
                type: 'annual',
              })
            }
          />
        </div>
        <div className="text-section">
          <h3>{t('tryFree')}</h3>
          <p className="sub-title">{t('cancelAnytime')}</p>
          <h2>{t('withYourSubscription')}</h2>
          <ul className="list">
            <li>{t('benefits.benefit1')}</li>
            <li>{t('benefits.benefit2')}</li>
            <li>{t('benefits.benefit3')}</li>
            <li>{t('benefits.benefit4')}</li>
            <li>{t('benefits.benefit5')}</li>
            <li>{t('benefits.benefit6')}</li>
          </ul>
        </div>
        <button
          type="button"
          onClick={handleOpenModal}
          className="pay-button mb-100"
        >
          {/* <SyncLoader
            color="#ffffff"
            loading={loading}
            cssOverride={override}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> */}
          {/*!loading &&*/ t('next')}
        </button>
      </div>
    </div>
  );
}

export default PlansPage;
