import './styles.css';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

function PlanOption({
  type,
  price,
  secondaryPrice,
  selected,
  discount,
  onSelect,
}) {
  const { t } = useTranslation();

  return (
    <div className="plan-container">
      <div
        onClick={onSelect}
        className={selected ? 'plan-option-selected' : 'plan-option'}
      >
        {discount && (
          <div className="discount-box">{t('discount', { discount })}</div>
        )}
        <h2>{t(type)}</h2>
        <h2>{`$${price} / ${type === 'monthly' ? t('month') : t('year')}`}</h2>
        <p>{`$${secondaryPrice} ${
          type === 'monthly' ? t('monthly') : t('priceAnnualy')
        }`}</p>
      </div>
    </div>
  );
}

export default PlanOption;
