/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import ninaTriste from '../../assets/images/nina-triste.svg';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import LogoutButton from '../../components/LogoutButton/LogoutButton';
import { ToastContainer } from 'react-toastify';

const logo = require('../../assets/images/fably-logo.png');

function UnsuccessfullPage() {
  const { t } = useTranslation();
  const { goTo } = useCommonFunctions();

  useEffect(() => {
    document.title = t('sorry');
  }, [t]);

  return (
    <div className="general-container">
      <div className="lg-background" />
      <LanguageBox />
      <LogoutButton />
      <img src={ninaTriste} alt={t('ninaTriste')} className="mobile-image" />
      <div className="container-l">
        <ToastContainer hideProgressBar={true} className="toast" />
        <div className="sub-container">
          <img
            src={logo}
            alt={t('fablyLogo')}
            className="fablyLogo desktop-image"
          />
          <img
            src={ninaTriste}
            alt={t('ninaTriste')}
            className="mt-60 desktop-image"
          />
          <div className="mid-text">
            <h3 className="nm-top-text cl-red">{`${t('ohNo')}`}</h3>
            <p className="nm-bottom-text">{t('problemWithPayment')}</p>
          </div>
          <button
            type="button"
            onClick={() => goTo('/')}
            className="loginButton"
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnsuccessfullPage;
