import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import flagEnglish from '../../assets/images/flag-english.svg';
import flagSpanish from '../../assets/images/flag-spanish.svg';
import i18n from 'i18next';
import './styles.css';

const LanguageBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { t } = useTranslation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  const getLanguageName = (code) => {
    return code === 'en' ? t('english') : t('spanish');
  };

  useEffect(() => {
    const detectedLanguage = i18n.language.split('-')[0] || 'en';
    setSelectedLanguage(detectedLanguage);
  }, []);

  return (
    <div className="language-box">
      <div className="selected-language" onClick={toggleDropdown}>
        <img
          src={selectedLanguage === 'en' ? flagEnglish : flagSpanish}
          alt={t('flagEnglish')}
          width={16}
          className="language-image"
        />{' '}
        <span className="language-span">
          {getLanguageName(selectedLanguage)}
        </span>
        {isOpen ? (
          <FiChevronUp className="arrow" size={18} />
        ) : (
          <FiChevronDown className="arrow" size={18} />
        )}
      </div>
      {isOpen && (
        <div className="dropdown">
          {selectedLanguage !== 'en' && (
            <div
              onClick={() => selectLanguage('en')}
              className="language-option"
            >
              <img
                src={flagEnglish}
                alt={t('flagEnglish')}
                width={16}
                className="language-image"
              />{' '}
              <span className="language-span">{t('english')}</span>
            </div>
          )}
          {selectedLanguage !== 'es' && (
            <div
              onClick={() => selectLanguage('es')}
              className="language-option"
            >
              <img
                src={flagSpanish}
                alt={t('flagEnglish')}
                width={16}
                className="language-image"
              />{' '}
              <span className="language-span">{t('spanish')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LanguageBox;
