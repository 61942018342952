import api from '../api';

export const createSubscription = async (subscriptionType, userId) => {
  try {
    const response = await api.post('/subscriptions/create', {
      subscriptionType,
      userId,
    });

    return response.data;
  } catch (error) {
    if (error.status === 404) {
      throw new Error('notFound');
    }
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('connectionError');
    }
  }
};

export const verifyUserSubscription = async () => {
  try {
    const response = await api.get('/subscriptions/check-account');
    return response.data;
  } catch (error) {
    if (error.status === 404) {
      throw new Error('notFound');
    }
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('connectionError');
    }
  }
};
