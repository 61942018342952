import { useEffect, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import ninaFeliz from '../../assets/images/nina-feliz.svg';
import { AppStoreButton } from 'react-mobile-app-button';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import LogoutButton from '../../components/LogoutButton/LogoutButton';
import { QRCodeSVG } from 'qrcode.react';
import ConfettiExplosion from 'react-confetti-explosion';
import { ToastContainer } from 'react-toastify';

const logo = require('../../assets/images/fably-logo.png');
const url = 'https://apple.co/4guNNhk';

function SuccessPage() {
  const { t } = useTranslation();
  const { goTo } = useCommonFunctions();
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setIsExploding(true);
    setTimeout(() => setIsExploding(false), 2000); // Detener lluvia después de 2s
  }, []);

  useEffect(() => {
    document.title = t('congratulations');
  }, [t]);

  return (
    <div className="general-container">
      <div className="lg-background" />
      <LanguageBox />
      <LogoutButton />
      <img
        src={ninaFeliz}
        alt={t('ninaFeliz')}
        className="mobile-image big-img"
      />
      <div className="container-l">
        <ToastContainer hideProgressBar={true} className="toast" />
        {isExploding && (
          <ConfettiExplosion
            particleCount={300}
            zIndex={100}
            force={0.7}
            particleSize={8}
            width={window.innerWidth}
            height={window.innerHeight * 1.5}
          />
        )}
        <div className="sub-container-start">
          <img
            src={logo}
            alt={t('fablyLogo')}
            className="fablyLogo desktop-image mt-60"
          />
          <div className="mt-40 mb-30 flex-column align-center-h">
            <img
              src={ninaFeliz}
              alt={t('ninaFeliz')}
              className="desktop-image"
            />
            <h3 className="nm-top-text cl-red">{`${t(
              'congratulationsPremiumSubscriber'
            )}`}</h3>
            <p className="nm-bottom-text sm-text">
              {t('nowYouHaveFullAccess')}
            </p>
            <h3 className="cl-red">{t('downloadAndEnjoy')}</h3>
            <div className="qr-code">
              <QRCodeSVG
                value={url}
                size={160}
                level={'H'}
                bgColor="#FFFFFF"
                fgColor="#000000"
                imageSettings={{
                  src: '../../assets/images/fably-logo.png',
                  width: 20,
                  height: 20,
                }}
              />
            </div>
            <div className="store-btn">
              <AppStoreButton
                url={url}
                theme="dark"
                title={t('downloadOnThe')}
                width={160}
              />
            </div>
            <button
              type="button"
              onClick={() => goTo('/')}
              className="loginButton mb-100"
            >
              {' '}
              {t('backToHomepage')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
