import { useLocation, useNavigate } from 'react-router-dom';

const useCommonFunctions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const goTo = (path, options = {}) => {
    navigate(path, options);
  };

  return { goTo, location };
};

export default useCommonFunctions;
