import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterComponent from './RouterComponent';
import './global.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <RouterComponent />
      </Router>
    </div>
  );
}

export default App;
