import { SyncLoader } from 'react-spinners';
import AuthController from '../../controllers/AuthController/AuthController';
import { useTranslation } from 'react-i18next';
import './styles.css';

const LogoutButton = () => {
  const { t } = useTranslation();
  const { handleLogOut, loading } = AuthController();

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  return (
    <button onClick={handleLogOut} className="logoutBtn" disabled={loading}>
      <SyncLoader
        color="#ffffff"
        loading={loading}
        cssOverride={override}
        size={3}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {!loading && t('logout')}
    </button>
  );
};

export default LogoutButton;
