import { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import PasswordInputWithIcon from '../../components/PasswordInputWithIcon/PasswordInputWithIcon';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import AuthController from '../../controllers/AuthController/AuthController';

const logo = require('../../assets/images/fably-logo.png');

function LoginPage() {
  const { t } = useTranslation();
  const {
    email,
    password,
    errorMessage,
    loading,
    setEmail,
    setPassword,
    handleLogin,
    goTo,
  } = AuthController();

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  useEffect(() => {
    document.title = t('login');
  }, [t]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');

    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container">
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <p className="logo-text">{t('getAccessFullContent')}</p>
          <div className="mid-text">
            <h4>{`${t('step')} 1: ${t('login')} / ${t('register')}`}</h4>
            <span>
              {t('dontHaveAccount')}{' '}
              <a href="/register" className="linkStyle">
                {t('signUp')}
              </a>
            </span>
          </div>
          <form onSubmit={handleLogin}>
            <input
              className="inputStyle"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder={t('email')}
              required
            />
            <PasswordInputWithIcon
              password={password}
              setPassword={setPassword}
              placeholder={t('password')}
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <p className="sub-text">{t('forgetPassword')}</p>
            <button type="submit" className="loginButton" disabled={loading}>
              <SyncLoader
                color="#ffffff"
                loading={loading}
                cssOverride={override}
                size={5}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              {!loading && t('login')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
