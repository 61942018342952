import { useLocation } from 'react-router-dom';

const usePersonalizedLocations = () => {
  const location = useLocation();

  const backgroundClass =
    location.pathname === '/login' || location.pathname === '/'
      ? 'david-bg'
      : location.pathname === '/register'
      ? 'sebastian-bg'
      : location.pathname === '/otp'
      ? 'football-bg'
      : location.pathname === '/AlreadyHaveAccount'
      ? 'football-bg'
      : location.pathname === '/plans'
      ? 'lesly-nico-bg'
      : '';

  return { backgroundClass };
};

export default usePersonalizedLocations;
