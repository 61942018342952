import { useEffect, useState } from 'react';

const useManageModal = (modalIsOpen) => {
  const [showModal, setShowModal] = useState(false);
  const [modalClass, setModalClass] = useState('modalStyle');

  useEffect(() => {
    if (modalIsOpen) {
      setModalClass('modalStyle modal-enter');
    } else {
      setModalClass('modalStyle modal-exit');
    }
  }, [modalIsOpen]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalClass('modalStyle modal-exit');
    setTimeout(setShowModal(false), 1000);
  };

  return {
    showModal,
    modalClass,
    setShowModal,
    handleOpenModal,
    handleCloseModal,
  };
};

export default useManageModal;
