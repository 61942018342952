import { useState } from 'react';

const useManageOtp = (handleSubmit) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    if (newOtp.every((num) => num !== '')) {
      handleSubmit(newOtp.join(''));
    }
  };

  const handleBackspace = (index) => {
    if (index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return { otp, setOtp, handleChange, handleBackspace };
};

export default useManageOtp;
