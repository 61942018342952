import { useEffect, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import PasswordInputWithIcon from '../../components/PasswordInputWithIcon/PasswordInputWithIcon';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import AuthController from '../../controllers/AuthController/AuthController';

const logo = require('../../assets/images/fably-logo.png');

function RegisterPage() {
  const { t } = useTranslation();
  const {
    name,
    email,
    password,
    errorMessage,
    loading,
    agree,
    repeatPassword,
    setName,
    setEmail,
    setPassword,
    setErrorMessage,
    setAgree,
    setRepeatPassword,
    handleRegister,
    goTo,
  } = AuthController();

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  useState(() => {
    if (errorMessage !== '') setErrorMessage('');
  }, [name, email, password, repeatPassword]);

  useEffect(() => {
    document.title = t('signUp');
  }, [t]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');

    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container">
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <div className="mid-text">
            <h4>{`${t('step')} 1: ${t('register')}`}</h4>
            <span>
              {t('alreadyHaveAccount')}{' '}
              <a href="/" className="linkStyle">
                {t('login')}
              </a>
            </span>
          </div>
          <form onSubmit={handleRegister}>
            <input
              className="inputStyle"
              type="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              placeholder={t('name')}
              required
            />
            <input
              className="inputStyle"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder={t('email')}
              required
            />
            <PasswordInputWithIcon
              password={password}
              setPassword={setPassword}
              placeholder={t('password')}
            />
            <PasswordInputWithIcon
              password={repeatPassword}
              setPassword={setRepeatPassword}
              placeholder={t('confirmPassword')}
            />
            <span className="registerSpan">
              <label className="labelStyle">
                <input
                  type="checkbox"
                  id="agree"
                  className="checkBox"
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                  required
                />
                {t('agree')}{' '}
                <a
                  href={t('termsLink')}
                  className="linkStyle"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('termsAndConditions')}
                </a>
              </label>
            </span>
            <button type="submit" className="RegisterButton">
              <SyncLoader
                color="#ffffff"
                loading={loading}
                cssOverride={override}
                size={5}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              {!loading && t('register')}
            </button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
