import { useState } from 'react';

const useSelectPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState({ type: 'annual' });

  const handleSelectPlan = (plan) => {
    if (selectedPlan?.type === plan.type) {
      setSelectedPlan(null);
    } else {
      setSelectedPlan(plan);
    }
  };

  return {
    selectedPlan,
    handleSelectPlan,
  };
};

export default useSelectPlan;
