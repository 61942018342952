import { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import AuthController from '../../controllers/AuthController/AuthController';

const logo = require('../../assets/images/fably-logo.png');

function SendOtpPasswordPage() {
  const { t } = useTranslation();
  const {
    email,
    errorMessage,
    loading,
    setEmail,
    handleSendOtpPassword,
    goTo,
  } = AuthController();

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  useEffect(() => {
    document.title = t('changePassword');
  }, [t]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');

    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container">
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <div className="mid-text">
            <p className="sub-title">{t('passwordReset')}</p>
          </div>
          <form onSubmit={handleSendOtpPassword}>
            <input
              className="inputStyle"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder={t('email')}
              required
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button type="submit" className="loginButton" disabled={loading}>
              <SyncLoader
                color="#ffffff"
                loading={loading}
                cssOverride={override}
                size={5}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              {!loading && t('send')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SendOtpPasswordPage;
